import styled from "styled-components"

export const Styles = styled.div`
  .logo-wrapper {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
  }
  .ButtonGoogle {
    margin-top: 30px;
    width: auto;
    padding-right: 20px !important;

    box-shadow: -1px 10px 23px 0px rgba(0, 0, 0, 0.75);
    border-radius: 29px !important;
  }
  .ButtonGoogle span {
    padding-right: 10px !important;
    color: black;
    font-weight: 700 !important;
  }
  .vertical-center {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .AtauLines {
    margin-top: 8% !important;
    width: 100%;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid #c6c3bc;
    line-height: 0.1em;
    margin: 10px 0 20px;
    color: #c6c3bc;
  }
  .AtauLines span {
    background: #fff;
    padding: 0 10px;
  }

  .sudah-punya-akun {
    padding-top: 0.5rem;

    a {
      color: black;
      font-weight: bold;
    }
  }

  @media only screen and (max-width: 1024px) {
  }
`
