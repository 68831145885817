import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RegisterComponent from "../components/AuthComponent/Register"

const RegisterPage = () => {
  return (
    <Layout noFooter>
      <SEO title="Register" />
      <RegisterComponent />
    </Layout>
  )
}

export default RegisterPage
