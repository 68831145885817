// Generic imports
import React, { useState } from "react"
import { connect } from "react-redux"
import { navigate, Link } from "gatsby"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { GoogleLogin } from "react-google-login"

// Redux imports
import * as sessionThunks from "../../../redux/slices/session/thunks"

// Utils imports

// Other components imports
import Input from "../../Reusable/Input"
import Button from "../../Reusable/Button"
import CardWrapper from "../card-wrapper"
import { Styles } from "./style"

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  loginWithGoogle: sessionThunks.loginWithGoogle,
  register: sessionThunks.register,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const RegisterComponent = ({ register, loginWithGoogle }) => {
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      display_name: "",
      phone: "",
      email: "",
      dob: null,
      password1: "",
      password2: "",
    },
    onSubmit: values => {
      if (values.password1 !== values.password2) {
        toast.error("Passwords do not match")
        return
      }
      setLoading(true)
      register(values)
        .then(() => {
          setTimeout(() => {
            navigate("/dashboard")
            setLoading(false)
          }, 2000)
        })
        .catch(() => {
          setLoading(false)
        })
    },
  })

  const responseGoogleSuccess = async response => {
    console.log(response)
    await loginWithGoogle({
      access_token: response.accessToken,
      id_token: response.tokenId,
    })
    setTimeout(() => {
      toast.success("Please complete your profile")
    }, 1000)
    setTimeout(() => {
      navigate("/dashboard")
    }, 2500)
  }

  const responseGoogleFailed = response => {
    console.log(response)
  }

  return (
    <CardWrapper>
      <Styles>
        <form onSubmit={formik.handleSubmit}>
          <div className="logo-wrapper">Daftar Sekarang</div>

          <div className="vertical-center">
            <GoogleLogin
              className="ButtonGoogle"
              clientId="362236836392-o5ne75msuchjgcme10d9h5lfs86qttsp.apps.googleusercontent.com"
              onSuccess={responseGoogleSuccess}
              onFailure={responseGoogleFailed}
              cookiePolicy={"single_host_origin"}
            >
              Daftar dengan Google
            </GoogleLogin>
          </div>

          <p className="AtauLines">
            {" "}
            <span>Atau</span>{" "}
          </p>
          <div className="input-wrapper">
            <Input
              className="InputRegister"
              type="text"
              name="display_name"
              placeholder="Type name"
              label="Name"
              required
              onChange={formik.handleChange}
              value={formik.values.display_name || ""}
            />
          </div>
          <div className="input-wrapper">
            <Input
              className="InputRegister"
              type="email"
              name="email"
              placeholder="Type email"
              label="Email"
              required
              onChange={formik.handleChange}
              value={formik.values.email || ""}
            />
          </div>
          <div className="input-wrapper">
            <Input
              className="InputRegister"
              type="text"
              name="phone"
              placeholder="Type phone number"
              label="Phone Number"
              required
              onChange={formik.handleChange}
              value={formik.values.phone || ""}
              maxLength={16}
            />
          </div>
          <div className="input-wrapper">
            <Input
              className="InputRegister"
              type="date"
              name="dob"
              label="Date of Birth"
              required
              onChange={formik.handleChange}
              value={formik.values.dob || ""}
            />
          </div>
          <div className="input-wrapper">
            <Input
              className="InputRegister"
              type="password"
              name="password1"
              placeholder="Type password"
              label="Password"
              required
              onChange={formik.handleChange}
              value={formik.values.password1 || ""}
            />
          </div>
          <div className="input-wrapper">
            <Input
              className="InputRegister"
              type="password"
              name="password2"
              placeholder="Type password again"
              label="Repeat Password"
              required
              onChange={formik.handleChange}
              value={formik.values.password2 || ""}
            />
          </div>
          <div className="sudah-punya-akun">
            Sudah memiliki akun? <Link to="/login">masuk</Link>
          </div>
          <div className="button-wrapper">
            <Button className="Button-Login" disabled={loading} type="submit">
              Mulai mendaftar
            </Button>
          </div>
        </form>
      </Styles>
    </CardWrapper>
  )
}

export default withConnect(RegisterComponent)
